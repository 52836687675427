const formItems = document.querySelectorAll('.js-form-item');

const checkInputValues = (input, label) => {
  if (label) {
    if (input.value.length) {
      label.classList.add('moved');
    } else {
      label.classList.remove('moved');
    }
  }
};

const initLabelMoving = () => {
  if (formItems.length) {
    formItems.forEach((el) => {
      const elInput = el.querySelector('input');
      const inputLabel = el.querySelector('.js-form-label');

      elInput.addEventListener('input', () => {
        checkInputValues(elInput, inputLabel);
      });

      checkInputValues(elInput, inputLabel);
    });
  }
};

export {initLabelMoving};
